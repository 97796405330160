import React, { FC, memo } from 'react';
import styles from './App.module.scss';
import Ink from 'react-ink';
import classNames from 'classnames';
import { map } from 'lodash';
import { Portal } from 'react-portal';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';

interface Props {}

const CheckDescribe: FC<{}> = ({ children }) => {
  return (
    <div className={styles.checkDescribe}>
      <span className={styles.check} />
      <div className={styles.describe}>{children}</div>
    </div>
  );
};

const Question: FC<{ title: string; answers: Array<string> }> = ({ title, answers }) => {
  return (
    <div className={styles.question}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.answers}>
        {map(answers, (answer, key) => {
          return (
            <div key={key} className={styles.answer}>
              <span>-</span>
              <span>{answer}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const App: FC<Props> = memo(() => {
  return (
    <div className={styles.app}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return (
              <React.Fragment>
                <div className={styles.thumbnail} />
                <div className={classNames(styles.section, styles.borderBottom)}>
                  <h1 className={styles.title}>
                    <span>감가심사센터</span>
                  </h1>
                  <div className={styles.describe}>
                    부당한 감가로 부터 고객을 보호하기 위해, 거래내용만을 집중 검토하는 감가심사팀이 있습니다.
                    <br />
                    평균 7년 경력의 13명의 본사 매니저가 모든 거래내용을 면밀히 검토하고 적정여부를 심사합니다.
                  </div>
                  <CheckDescribe>
                    올리신 차량설명과 실제 차량의 주요사항이
                    <br />
                    같다면, 감가없음을 약속합니다.
                  </CheckDescribe>
                  <CheckDescribe>
                    기재되지 않은 사고내역이나 수리 필요사항이
                    <br />
                    있다면, 헤이딜러 가이드 기준으로 설명드리고
                    <br />
                    견적조정을 협의드립니다.
                    <Link to="/reduction" className={styles.link}>
                      헤이딜러 감가기준 &gt;
                      <Ink />
                    </Link>
                  </CheckDescribe>
                  <CheckDescribe>
                    딜러는 검차결과를 투명하게 기록하고,
                    <br />
                    본사 감가심사센터에서 적정여부를 심사합니다.
                    <br />
                    <p className={styles.highlight}>이 과정은 고객에게도 투명하게 공개됩니다.</p>
                    <img src={require('../../images/preview.jpg')} alt="감가사진" width={288} />
                    <p className={styles.hidden}>* 고객에게 보여지는 검차결과 실제 화면</p>
                  </CheckDescribe>
                </div>
                <div className={classNames(styles.section, styles.last)}>
                  <Question
                    title="Q. 감가의 적정여부는 어떻게 판단하나요?"
                    answers={[
                      `일반적으로 딜러가 검차 당시 기재한 내용과\n사진을 기초로 판단합니다.`,
                      `감가금액이 크거나 육안으로 판단이 힘든 경우, 딜러에게 차량 수리내역서, 성능지 등의 추가 서류를 받고 있습니다.`,
                    ]}
                  />
                  <Question
                    title="Q. 부당감가가 발견되면 어떻게 되나요?"
                    answers={[
                      `연식, 차종에 따른 감가 기준 금액을 초과한 경우\n허위사실로 감가를 한 경우`,
                      `고객님께 해당내용에 대하여 유선으로 안내해드리고, 감가분은 고객님 계좌로 환급됩니다.`,
                    ]}
                  />
                </div>
              </React.Fragment>
            );
          }}
        />
        <Route
          path="/reduction"
          render={() => {
            return <iframe className={styles.iframe} src="https://api.heydealer.com/posts/p4J3n5wr/" />;
          }}
        />
      </Switch>
      <Portal>
        <div className={styles.bottom}>
          <a href="heydealer://register" className={styles.button}>
            2분만에 경매신청 &gt;
            <Ink />
          </a>
        </div>
      </Portal>
    </div>
  );
});

export default App;
