import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export default combineReducers<Application>({
  router: connectRouter(history),
});

export interface Application {
  router: RouterState;
}
